import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { withTranslation , useTranslation} from 'react-i18next';
import 'app/modules/IdProofing/AppsAccessPopup.scss';
import faceScan from './img/faceScan.png';
import closeButton from './img/closeButton.png';
import warningButton from './img/warning.png';
import { Progress } from 'react-sweet-progress';
import Cookies from 'js-cookie';
import http from 'api/http-request';
import 'react-sweet-progress/lib/style.css';
import { motion, AnimatePresence } from 'framer-motion';
import idPlusAnimation from 'assets/json/idPlus_socure.json';
import finsihAnimation from 'assets/json/verified.json';

function AppsAccessPopup({ closePopup }) {
  const { t } = useTranslation();
  const [screen, setScreen] = useState(1);
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState('default');
  const idPlus = {
    loop: true,
    autoplay: true,
    animationData: idPlusAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onStartIdProofing = () => {
    if(Cookies.get('idProofingStatus') !== '-4'){
      window.location.href = '/idproofing-landing';
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="popupBackground-idproofing"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="popupContainer-idproofing"
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
        >
          <div className="titleCloseBtn">
            {/* <button onClick={() => closePopup()}> X </button> */}
            <img
              className="closeBtn"
              onClick={() => closePopup()}
              alt="Close"
              src={closeButton}
              data-holder-rendered="true"
            />
          </div>
              <div className="popupTitle-idproofing">
                <h3>
                {t('pages.Apps.popupHeader')}
                </h3>
              </div>
              <div className="popupBody-idproofing">
                <div className="popupBody-idproofing-image">
                  {/* <img
                    alt="100x100"
                    src={faceScan}
                    data-holder-rendered="true"
                  /> */}
                  <Lottie options={idPlus} height={150} width={150} />
                </div>
                <p>
                {t('pages.Apps.popupDesc')}
                </p>
              </div>
              <div className="popupFooter-idproofing">
                <button id="cancelBtn" onClick={() => closePopup()}>
                  {t('pages.ManageServices.cancel')}
                </button>
                <button onClick={onStartIdProofing}>Continue</button>
              </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

AppsAccessPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AppsAccessPopup);
